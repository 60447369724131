import Swiper from 'swiper';

export default function slider() {
    var swiper1 = new Swiper('.swiper-slider', {
        spaceBetween: 20,
        breakpoints: {
            640: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            },
            1024: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            1440: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            }
        },

        loop: false,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        on: {
            init: function () {
                updatePagination(this);
            },
            slideChange: function () {
                updatePagination(this);
            }
        },
    });

    function updatePagination(swiper1) {
        const paginationContainer = document.querySelector('.slider_custom_pagination');
        paginationContainer.innerHTML = '';
        const numberOfDots = Math.ceil(swiper1.slides.length / swiper1.params.slidesPerView);

        for (let i = 0; i < numberOfDots; i++) {
            const dot = document.createElement('div');
            dot.addEventListener('click', () => {
                swiper1.slideTo(i * swiper1.params.slidesPerView);
            });
            if (i === Math.floor(swiper1.realIndex / swiper1.params.slidesPerView)) {
                dot.classList.add('active');
            }
            paginationContainer.appendChild(dot);
        }
    }
}
