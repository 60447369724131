import slider          from './components/slider'
import portfolioSlider from './components/portfolio-slider';
import cardProductJQ   from './components/card-productJQ';
import clientsSlider from './components/clients';
import jquery        from './components/jquery';

(function () {
	slider();

	portfolioSlider();
	clientsSlider();

	const modal        = document.querySelector('.modal')
	const modalOpen    = document.querySelector('.question_block_send')
	const modalOpenTel = document.querySelector('.header_tel_number')
	const modalClose   = document.querySelector('.modal_block_upper_close')
	const modalBlur = document.querySelectorAll('.blur-layer')


	const navMenu = document.querySelector('.nav_menu_main')
	const navMenuItems = document.querySelector('.nav_menu_main_items')
	const navSearch = document.querySelector('.nav_menu_search')
	const navAccordion = document.querySelectorAll('.nav_menu_main_acc')
	//	Меню услуги
	navMenu.addEventListener('click', function () {
		navMenu.classList.toggle('active');
		navMenuItems.classList.toggle('active')
		if (navMenu.classList.contains('active')) {
			if (navSearch.classList.contains('active')) {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 0 0';
			} else {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 27px 0';
			}
		} else {
			if (navSearch.classList.contains('active')) {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 0 27px';
			} else {
				document.querySelector('.nav_inner').style.borderRadius = '27px';
			}
		}
	});
	// закрытие nav при клике на ссылки
	document.querySelectorAll('.nav_menu_main_items_item').forEach(function(i) {
		i.addEventListener('click', function() {
			navMenu.classList.remove('active')
			navMenuItems.classList.remove('active')
		})
	})
	// Поиск
	document.querySelector('.nav_menu_search_icon').addEventListener('click', function () {
		navSearch.classList.toggle('active');
		if (navSearch.classList.contains('active')) {
			if (navMenu.classList.contains('active')) {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 0 0';
			} else {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 0 27px';
			}
		} else {
			if	(navMenu.classList.contains('active')) {
				document.querySelector('.nav_inner').style.borderRadius = '27px 27px 27px 0';
			} else {
				document.querySelector('.nav_inner').style.borderRadius = '27px';
			}
		}
	});
	// accordion menu
	navAccordion.forEach(function(acc){
		acc.addEventListener('click', function() {
			acc.classList.toggle('active')
		})
	})
	let navLink = document.querySelectorAll('.nav-item')




	// Мобилка меню
	// document.querySelector('.nav_menu_mobile').addEventListener('click', function () {
	// 	this.classList.toggle('active-mobile');
	// 	document.querySelector('.nav_inner').classList.toggle('active-mobile');
	// });

	modalOpen.addEventListener('click', function () {
		modal.classList.add('active');
		if (modal.classList.contains('active')) {
			modalBlur.forEach((i) => i.style.filter = 'blur(5px)')
		} else {
			modalBlur.forEach((i) => i.style.filter = 'blur(0)')
		}
	});

	modalOpen.addEventListener('click', modalOpenFunc);
	modalOpenTel.addEventListener('click', modalOpenFunc);


	function modalOpenFunc() {
		modal.classList.add('active');
		if (modal.classList.contains('active')) {
			modalBlur.forEach((i) => i.style.filter = 'blur(5px)')
			document.querySelector('.nav').style.position = 'relative'
		} else {
			modalBlur.forEach((i) => i.style.filter = 'blur(0)')
			document.querySelector('nav').style.position = 'fixed'
		}
	}


	function modalCloseFunc() {
		modal.classList.remove('active')
		modalBlur.forEach((i) => i.style.filter = 'blur(0)')
	}

	modalClose.addEventListener('click', modalCloseFunc);

	cardProductJQ();
	jquery()
})();
