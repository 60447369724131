export default function cardProductJQ() {


	// карточка товара описание
	let $pDesc = $('.product_description');
	let $pDescBtn = $pDesc.find('.product_description_tabs_item');
	let $pDescItem = $pDesc.find('.product_description_items_item');

	function pDescChange(arr, i) {
		$.each(arr, function(index, item) {
			$(item).removeClass('active'); // Удаляем класс 'active' у всех элементов
			$(item).eq(i).addClass('active'); // Добавляем класс 'active' только к элементу с индексом i
		});
	}

	$pDescBtn.each(function(i) {
		$(this).on('click', function() {
			pDescChange([$pDescBtn, $pDescItem], i);
		});
	});


	// jq картинки

	jQuery(document).ready(function($) {

		$('body').on('click', '.callback-toggle', function() {
			$.magnificPopup.open({
				type        :'inline',
				removalDelay:500,
				mainClass   :'mfp-fade',
				midClick    :true,
				items       :{
					src:'.callback-modal-toggle'
				}
			});
		});

		// УВЕЛИЧЕНИЕ КАРТИНКИ
		$('.zoom-img').magnificPopup({
			type        :'image',
			removalDelay:500,
			mainClass   :'mfp-fade'
		});

		// ГАЛЕРЕЯ
		$('.gallery').each(function() {
			var test = $(this).find('a');
			$(test).magnificPopup({
				type        :'image',
				removalDelay:500,
				mainClass   :'mfp-fade',
				gallery     :{
					enabled :true,
					tCounter:''
				}
			});
		});

		if ($('.sliderImgT').length > 0) {
			$('.sliderImgT').slick({
				autoplay      :false,
				autoplaySpeed :3500,
				slidesToShow  :1,
				slidesToScroll:1,
				dots          :false,
				swipeToSlide  :false,
				arrows        :true,
				infinite      :false,
				speed         :750,
				fade          :true,
				easing        :'easeInOutQuart',
				slide         :'.slideE',
				adaptiveHeight:true,
				asNavFor      :'.sliderImgB',
			});
		}
		if ($('.sliderImgB').length > 0) {
			$('.sliderImgB').slick({
				centerMode    :false,
				focusOnSelect :true,
				autoplay      :false,
				autoplaySpeed :3500,
				slidesToShow  :5,
				slidesToScroll:1,
				dots          :false,
				arrows        :false,
				infinite      :false,
				speed         :750,
				easing        :'easeInOutQuart',
				slide         :'.sliderESmall',
				adaptiveHeight:true,
				asNavFor      :'.sliderImgT',
				responsive    :[
					{
						breakpoint:600,
						settings  :{
							slidesToShow:3
						}
					},
					{
						breakpoint:500,
						settings  :{
							arrows       :false,
							centerMode   :true,
							centerPadding:'40px',
							slidesToShow :2
						}
					},
					{
						breakpoint:400,
						settings  :{
							arrows       :false,
							centerMode   :true,
							centerPadding:'40px',
							slidesToShow :1
						}
					}
				]
			});
		}
	});

	//корзина
	jQuery(document).ready(function($) {

		$('.closemodal').click(function() {
			$.magnificPopup.close();
		});


		var cart = JSON.parse(localStorage.getItem('cart'));
		if (cart == null) {
			var cart     = {};
			cart.items   = [];
			cart.quentys = {};
			cart.namets  = {};
		}

		if (localStorage.getItem('cart') != null) {

			var items = JSON.parse(localStorage.getItem('cart')).items;

			if (items.length) {
				$('.cart.callback-toggle').append('<span class=\'count\'>' + items.length + '</span>')
				$('.cart.callback-toggle').addClass('active');
				$('.cartcontent').addClass('active');
			}
			for (var i = 0; i < items.length; i++) {

				addItem(items[i]);
				$('#' + items[i]).toggleClass('activeitem');
				toggleText(items[i]);
				cart.items.push(items[i]);
			}

		}

		$('.btnclick').click(function(e) {
			var namet = $(this).data('namet');

			$(this).addClass('activeitem');
			toggleText($(this).attr('id'));
			setLocalStorage(e.target.id, 0, namet);

			if (!$(`li[data-attribute="${e.target.id}"]`).length) {
				addItem(e.target.id);
			} else {
				//$(`li[data-attribute="${e.target.id}"]`).remove();
			}
			$.magnificPopup.open({
				type        :'inline',
				removalDelay:500,
				mainClass   :'mfp-fade',
				midClick    :true,
				items       :{
					src:'.callback-modal-toggle2'
				}
			});

		});

		$('.callback-modal').on('click', '.btnnoclick', function() {
			removeItem($(this).parent('li'));
			setLocalStorage($(this).data('id'), 1);

			var items = JSON.parse(localStorage.getItem('cart')).items;
			console.log(items.length);
			if (items.length == 0) {
				$('.cartcontent').removeClass('active');
			}
		});


		// ОТПРАВКА ФОРМЫ
		$('.form-submit-toggle').submit(function(e) {
			e.preventDefault();
			var $form      = new FormData(this);
			var $formclear = $(this);


			$.ajax({
				type       :'POST',
				url        :'/index.php?option=com_request',
				data       :$form,
				processData:false,
				contentType:false,

				beforeSend:function() {
					$.magnificPopup.open({
						type        :'inline',
						removalDelay:500,
						mainClass   :'mfp-fade',
						midClick    :true,
						items       :{
							src:'<div class="callback-modal-message">' +
								'<div class="callback-modal-message__text"><span class="callback-modal-message__process">Идет отправка сообщения...</span></div>' +
								'</div>'
						}
					});
				},

				success:function(response) {
					$lol = JSON.parse(response);
					if ($lol['status'] == 'saveAndSend') {
						$('.callback-modal-message__text').html(
							'<span class="callback-modal-message__success">Сообщение успешно отправлено!</span>');
						//ym(62411503, 'reachGoal', 'sendcarttoshop');
						// close modal message
						if (localStorage.getItem('cart') != null) {
							var items = JSON.parse(localStorage.getItem('cart')).items;

							for (var i = 0; i < items.length; i++) {

								$('#' + items[i]).removeClass('activeitem');
								toggleText(items[i]);
							}

							$('.cartcontent').removeClass('active');
							$('.cart.callback-toggle').removeClass('active');

							$('.cartcontent').html(`<li>
												<span>Название</span>
												<span>Кол-во</span>
												<span>Удалить</span>
																</li>`);


							localStorage.clear('cart');
						}
						// $('callback-toggle').removeClass('activeitem');
						// $('callback-toggle').text("ЗАКАЗАТЬ");
						setTimeout(function() {

							$formclear.trigger('reset');

							$.magnificPopup.close();
						}, 3000);


					} else {
						if ($lol['status'] == 'captcha') {
							$('.callback-modal-message__text').html(
								'<span class="callback-modal-message__error">Отсутствует проверочное поле капчи в форме!</span>');
							setTimeout(function() {
								$formclear.trigger('reset');
								$.magnificPopup.close();
							}, 3000);
						} else {
							$('.callback-modal-message__text').html(
								'<span class="callback-modal-message__error">Ошибка отправки сообщения!</span>');
							setTimeout(function() {
								$formclear.trigger('reset');
								$.magnificPopup.close();
							}, 3000);
						}
					}

					(typeof updateCaptcha !== 'undefined') && updateCaptcha();
				},

				error:function(response) {
					// dump error

					console.error(response.message);
				},

				// after success or error
				complete:function() {
				}
			});
		});

	});

	function removeItem(item) {

		var id = item[0].dataset.attribute;
		$(`#${id}`).removeClass('activeitem');
		toggleText(id);
		item.remove();
	}

	function addItem(item) {
		var items = JSON.parse(localStorage.getItem('cart')).items;
		if (items.length) {
			$('.cartcontent').addClass('active');

		}


		var namets = JSON.parse(localStorage.getItem('cart')).namets;


		$('.cartcontent').append(
			`<li class="cart-item-content" data-attribute='${item}'>
<input class="cart-item disabled" type="text" name="(${item})НазваниеТовара" value="${namets[item]}" readonly>
<div class="cart_item_number">
<input class="cart-item disabled" type="number" name="(${item})КоличествоТовара" value="1">
<span>шт</span>
</div>
<button class='cart-item-delete btn btnnoclick' data-id='${item}'>Удалить</button>
</li>`
		);
	}


	function toggleText(item) {
		if ($('#' + item).hasClass('activeitem')) {
			$('#' + item).text('УЖЕ В КОРЗИНЕ');
		} else {
			$('#' + item).text('ЗАКАЗАТЬ');
		}
	}


	function setLocalStorage(id, flag, namet = false) {
		var cart = JSON.parse(localStorage.getItem('cart'));
		if (cart == null) {
			var cart     = {};
			cart.items   = [];
			cart.quentys = {};
			cart.namets  = {};
		}

		if (flag) {

			delete cart.namets[id];


			for (var i = cart.items.length - 1; i >= 0; i--) {
				if (cart.items[i] === id) {
					cart.items.splice(i, 1);
				}
			}

		} else {

			const addToObj = (obj, key, value) => {
				if (!obj.hasOwnProperty(key)) {
					obj[key] = value;
				}
				return obj;
			};

			var regex = /[!,.,:;]/g;

			var cleanedString = namet.replace(regex, '');

			addToObj(cart.namets, id, cleanedString);

			if (cart.items.indexOf(id) == -1) {
				cart.items.push(id);
			}

		}

		if (cart.items.length) {
			var countElement = jQuery('.cart.callback-toggle .count');
			if (countElement.length) {
				countElement.text(cart.items.length);
			} else {
				jQuery('.cart.callback-toggle').append('<span class=\'count\'>' + cart.items.length + '</span>');
			}
			jQuery('.cart.callback-toggle').addClass('active');
		} else {
			jQuery('.cart.callback-toggle').removeClass('active');
		}

		console.log(JSON.stringify(cart));
		localStorage.setItem('cart', JSON.stringify(cart));
	} //корзина





}
