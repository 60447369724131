
export default function jquery () {

	// выбор файла в корзине
	$('.input-file input[type=file]').on('change', function() {
		let file = this.files[0];
		let $inputFileText = $(this).closest('.input-file').find('.input-file-text');
		let $inputFileBtn = $(this).closest('.input-file').find('.input-file-btn');

		// Обновляем текст с именем файла
		if (file) {
			$inputFileText.html(file.name);
			$inputFileBtn.text('Удалить'); // Заменяем текст на "Удалить"
			$inputFileBtn.addClass('active'); // Добавляем класс active к кнопке
		} else {
			$inputFileText.html('Выбрать файл');
			$inputFileBtn.text('Добавить'); // Заменяем текст на "Добавить"
			$inputFileBtn.removeClass('active'); // Убираем класс active с кнопки
		}
	});

	// Очистка выбранного файла по клику на кнопку
	$('.input-file-btn').on('click', function() {
		if ($(this).hasClass('active')) {
			// Очищаем поле input
			$(this).closest('.input-file').find('input[type=file]').val(null);
			$(this).removeClass('active'); // Убираем класс active с кнопки
			$(this).closest('.input-file').find('.input-file-text').html('Выбрать файл'); // Сбрасываем текст
			$(this).text('Добавить'); // Сбрасываем текст кнопки
		}
	});

	// Очистка выбранных файлов при перезагрузке страницы
	$(document).ready(function() {
		$('input[type=file]').val(null);
	});
}
