import Swiper from 'swiper';

export default function clientsSlider() {
    var swiper = new Swiper('.clients_content', {
        spaceBetween:20,
        loop        :false,
        breakpoints :{
            425 :{
                slidesPerView :2,
                slidesPerGroup:1,
            },
            640 :{
                slidesPerView :2,
                slidesPerGroup:1,
            },
            1024:{
                slidesPerView :4,
                slidesPerGroup:1,
            },
            1440:{
                slidesPerView :6,
                slidesPerGroup:1,
            },
        },

        autoplay  :{
            delay               :10000,
            disableOnInteraction:false,
        },
        navigation:{
            nextEl:'#next',
            prevEl:'#prev',
        },
    });
}


