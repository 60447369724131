import Swiper from 'swiper';

export default function portfolioSlider() {

    var swiperPortfolio = new Swiper('.swiper-portfolio', {
        spaceBetween:20,
        breakpoints :{
			640: {
				slidesPerView: 2,
				slidesPerGroup: 2,
			},
			1024: {
				slidesPerView: 3,
				slidesPerGroup: 3,
			},
			1440: {
				slidesPerView: 4,
				slidesPerGroup: 4,
			}
        },

        loop    :false,
        autoplay:{
            delay               :10000,
            disableOnInteraction:false,
        },
        on      :{
            init       :function () {
                updatePagination1(this);
            },
            slideChange:function () {
                updatePagination1(this);
            }
        }
    });

    function updatePagination1(swiper2) {
        const paginationContainer     = document.querySelector('.portfolio_custom_pagination');
        paginationContainer.innerHTML = '';
        const numberOfDots            = Math.ceil(swiper2.slides.length / swiper2.params.slidesPerView);


        for (let i = 0; i < numberOfDots; i++) {
            const dot = document.createElement('div');
            dot.addEventListener('click', () => {
                swiper2.slideTo(i * swiper2.params.slidesPerView);
            });
            if (i === Math.floor(swiper2.realIndex / swiper2.params.slidesPerView)) {
                dot.classList.add('active');
            }
            paginationContainer.appendChild(dot);
        }
    }
}
